figure:hover .link-label,
figure.active .link-label {
    opacity: 1;
}
.pillars {
    margin: 50px auto;
    width: 150px;
    height: 500px;
    position: relative;
    perspective: 1000px;
}
.pillars #carousel {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    animation: rotation 13s infinite linear;
}
.pillars #carousel figure {
    display: block;
    position: absolute;
    width: 140px;
    height: 500px;
    left: 0;
    top: 0;
    background: black;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    border: solid 1px black;
    transition: all 0.5s ease;
}
.pillars #carousel figure:nth-child(1) {
    transform: rotateY(0deg) translateZ(288px);
}
.pillars #carousel figure:nth-child(2) {
    transform: rotateY(-40deg) translateZ(288px);
}
.pillars #carousel figure:nth-child(3) {
    transform: rotateY(-80deg) translateZ(288px);
}
.pillars #carousel figure:nth-child(4) {
    transform: rotateY(-120deg) translateZ(288px);
}
.pillars #carousel figure:nth-child(5) {
    transform: rotateY(-160deg) translateZ(288px);
}
.pillars #carousel figure:nth-child(6) {
    transform: rotateY(-200deg) translateZ(288px);
}
.pillars #carousel figure:nth-child(7) {
    transform: rotateY(-240deg) translateZ(288px);
}
.pillars #carousel figure:nth-child(8) {
    transform: rotateY(-280deg) translateZ(288px);
}
.pillars #carousel figure:nth-child(9) {
    transform: rotateY(-320deg) translateZ(288px);
}
.glitch {
    animation-name: glitch-anim-text;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
.glitch2 {
    animation-name: glitch-anim-text2;
    animation-duration: 7s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
.glitch3 {
    animation-name: glitch-anim-text3;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
.glitch4 {
    animation-name: glitch-anim-text3;
    animation-duration: 9s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
@keyframes glitch-anim-text {
    0% {
        transform: translate3d(calc(-1 * 10px), 0, 0) scale3d(-1, -1, 1);
        -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
        clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }
    2% {
        -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
        clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }
    4% {
        -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
        clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }
    5% {
        -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
        clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }
    6% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
        clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }
    7% {
        -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
        clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }
    8% {
        -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
        clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
    9% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
        clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    }
    9.9% {
        transform: translate3d(calc(-1 * 10px), 0, 0) scale3d(-1, -1, 1);
    }
    10%,
    100% {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}
@keyframes glitch-anim-text2 {
    0% {
        transform: translate3d(calc(-1 * 15px), 0, 0) scale3d(-1, -1, 1);
        -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 21%, 0 21%);
        clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }
    2% {
        -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
        clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }
    4% {
        -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
        clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }
    5% {
        -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
        clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }
    6% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
        clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }
    7% {
        -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
        clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }
    8% {
        -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
        clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
    9% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
        clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    }
    9.9% {
        transform: translate3d(calc(-1 * 20px), 0, 0) scale3d(-1, -1, 1);
    }
    10%,
    100% {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}
@keyframes glitch-anim-text3 {
    0% {
        transform: translate3d(calc(-1 * 40px), 0, 0) scale3d(-1, -1, 1);
        -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
        clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }
    2% {
        -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
        clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }
    4% {
        -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
        clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }
    5% {
        -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
        clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }
    6% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
        clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }
    7% {
        -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
        clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }
    8% {
        -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
        clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
    9% {
        -webkit-clip-path: polygon(0 70%, 100% 60%, 100% 80%, 0 80%);
        clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    }
    9.9% {
        transform: translate3d(calc(-1 * 10px), 0, 0) scale3d(-1, -1, 1);
    }
    10%,
    100% {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}
@keyframes glitch-anim-text4 {
    0% {
        transform: translate3d(calc(-1 * 10px), 0, 0) scale3d(-1, -1, 1);
        -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
        clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }
    2% {
        -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
        clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }
    4% {
        -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
        clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }
    5% {
        -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
        clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }
    6% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
        clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }
    7% {
        -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
        clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }
    8% {
        -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
        clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
    9% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
        clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    }
    9.9% {
        transform: translate3d(calc(-1 * 10px), 0, 0) scale3d(-1, -1, 1);
    }
    10%,
    100% {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}
@keyframes rotation {
    from {
        transform: rotateY(0deg);
    }
    to {
        transform: rotateY(360deg);
    }
}
