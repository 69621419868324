@font-face {
    font-family: 'Industry';
    src: url('./fonts/Industry-Black.woff2') format('woff2'), url('./fonts/Industry-Black.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Industry Ultra';
    src: url('./fonts/Industry-Ultra.woff2') format('woff2'), url('./fonts/Industry-Ultra.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Industry Book';
    src: url('./fonts/Industry-Book.woff2') format('woff2'), url('./fonts/Industry-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Industry';
    src: url('./fonts/Industry-Thin.woff2') format('woff2'), url('./fonts/Industry-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Industry';
    font-weight: 100;
}

h1,
h2,
h3 {
    letter-spacing: 0.3em;
}

p,
a {
    font-family: 'Industry Book';
    font-weight: normal;
}

a:link,
a:visited {
    text-decoration: none;
    text-transform: uppercase;
    color: white;
    margin: 0.5em auto;
    transition: 0.2s;
    cursor: pointer;
}

a:hover,
a:visited:hover {
    color: red;
}

img {
    max-width: 100%;
    height: auto;
}

* {
    box-sizing: border-box;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: black;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

html,
body {
    width: 100%;
    height: 100%;
}

body {
    font-family: 'Industry Book', -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial,
        sans-serif;
    font-weight: normal;
    color: white;
    letter-spacing: 0.22rem;
    overflow: hidden;
}

.canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    canvas {
        width: 100vw;
        height: 100vh;
    }
}

.scroll-container {
    position: absolute;
    overflow: auto;
    top: 0px;
    width: 100%;
    height: 100%;
}

#root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.ui {
    position: absolute;
    width: 100%;
    height: 105vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    will-change: transform, opacity;
    text-shadow: 0px 2px 40px #00000020, 0px 2px 5px #00000030;
    background: transparent;
    z-index: 50;
}

nav {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
}

.hair {
    position: fixed;
    margin-top: -3px;
    margin-left: -2px;
    background: transparent;
    border-top: 1px dotted red;
    border-left: 1px dotted red;
    pointer-events: none;
    z-index: 1000;
}

.page {
    text-align: center;
    text-transform: uppercase;

    p,
    a,
    a:visited {
        display: block;
        color: red;
        text-transform: uppercase;
        transition: 0.2s;
        cursor: pointer;

        &:hover {
            color: white;
        }
    }

    button {
        font-family: 'Industry Book';
        font-weight: normal;
        font-size: 1em;
        color: red;
        outline: none;
        border: none;
        border-radius: 0;
        background: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: 0.2s;
        letter-spacing: 0.22em;
        text-transform: uppercase;

        &:hover {
            color: white;
        }
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


@media screen and (min-width: 0px) and (max-width: 768px) {

    .spaced {
        margin-top: 1em;
    }
    
    .album {
        width: 300px;
        height: auto;
        display: block;
        margin: 0 auto;
    }
    h1, h2, h3 {
        font-weight: 300;
          font-family: 'Industry Book';
        
    }
    .purchase-options {
        li {
            margin: 7px !important;
            a {
                img {
                    width: 20px;
                }
            }
        }

    }
    ul {
    &.purchase-options {
        margin: 15px auto !important;
    }
        }

    .ui {
        height: 100vh;
    }

    .cta {
        display: none;
    }

    h2 {
        &.front-page {
            font-size: 15px;

        }

        &.top-pad {
           margin-top: 20px !important;
        }
    }

    h1 {
        &.front-page {
            margin: 0 auto 20px;
             font-size: 20px;
        }
        
    }

    h3 {
        &.front-page {

            font-size: 13px;
            line-height: 14px;
            margin: 0 auto 15px;
        }


    }

}